import { LogLevel } from '@nts/std/src/lib/utility';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';

export const environment: EnvironmentConfiguration = {
  production: true,
  baseAppUrl: 'https://ntssubjectwebapp.ntsinformatica.it',
  authenticationAppUrl: 'https://ntsloginservice.ntsinformatica.it/auth/login',
  prefixApi: '/api',
  envType: 'DEV',
  appTitle: 'Subject - DEV',
  appSubTitle: '',
  logLevel: LogLevel.All,
  isEnterpriseBarrierRequired: true,
};
