import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StdModule, NtsAppRootModule } from '@nts/std';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment as devEnvironment } from 'src/environments/environment.dev';
import { NtsBootService } from '@nts/std';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT, unproxiedEnvironmentFactory } from '@nts/std/src/lib/environments';
import { AzureMapsModule, setAzureMapsConfiguration } from 'ng-azure-maps';
import { AuthenticationType } from 'azure-maps-control';
import { firstValueFrom } from 'rxjs'
import { ConfigurationLoader } from '@nts/std/src/lib/utility';

export function setAzureMapsAuthentication(
  configurationLoader: ConfigurationLoader
): () => Promise<void> {
  return async (): Promise<void> => {

    const configuration = await firstValueFrom(configurationLoader.getConfiguration());
    if (configuration?.maps?.azureSubscriptionKey?.length > 0) {
      setAzureMapsConfiguration({
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: configuration.maps.azureSubscriptionKey
        }
      });
    }
  };
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    StdModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AzureMapsModule.forRoot(),
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    ...StdModule.getRootProviders(),
    {
      provide: APP_INITIALIZER,
      useFactory: setAzureMapsAuthentication,
      deps: [ConfigurationLoader],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {
  constructor(
    bootService: NtsBootService,
  ) {
    super(bootService);
  }
}
